import React, { FC, useState, useEffect } from 'react'
import { Tile, TileProps } from 'modules/Home/components/Tile'
import {
  PodHistoryDonut,
  PodHistoryPeriod,
  setPodHistoryDonutChartState,
} from 'modules/UsageInsights/charts/PodHistoryDonut'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { WebAppInsights } from 'analytics-events'

const DONUT_MAX_DIAMETER = 172

export const PodsThisWeek: FC<TileProps> = ({ ...rest }) => {
  const { reportEvent } = useAnalytics()
  const [hasPods, setHasPods] = useState<boolean>(null)
  const [hasUnknownFlavor, setHasUnknownFlavor] = useState<boolean>(null)

  useEffect(() => {
    if (hasPods === null || hasUnknownFlavor === null) return

    reportEvent(WebAppInsights.PodsThisWeekEvent({
      chartState: setPodHistoryDonutChartState(hasPods, hasUnknownFlavor),
      isValid: true,
    }))
  }, [hasPods, hasUnknownFlavor])

  return (
    <Tile {...rest}>
      <Tile.Heading
        color='graphica|base|100'
        content='Pods this week'
        margin={{ bottom: 'baseLoose' }}
      />
      <PodHistoryDonut
        timePeriod={PodHistoryPeriod.CalendarWeek}
        maxDiameter={DONUT_MAX_DIAMETER}
        setHasPods={setHasPods}
        setHasUnknownFlavor={setHasUnknownFlavor}
      />
    </Tile>
  )
}
